import { Injectable } from '@angular/core';
import { HttpClient, HttpEvent, HttpHeaders, HttpParams, HttpProgressEvent, HttpRequest, HttpResponse } from '@angular/common/http';
import * as JsonObjects from '../../../shared/classes';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../../store/reducer'//'/src/store/reducer';
import { FileInput } from 'ngx-material-file-input';
import { FormFieldData, UserInputs } from '../../../shared/classes';
import { catchError, map, share, startWith } from 'rxjs/operators';
import { FormComponent } from '../../../shared/structureObject';
import { AccountService } from 'src/app/account.service';

const baseUrl = //"https://localhost:44358";//local
'https://join.infinity.co.il/dataapi';//prod
//'https://dev-join.infinity.co.il/dataapi';//dev

@Injectable({ providedIn: 'root' })
export class APIService {
  processNum: string = "";
  RunNum: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
    }),
  };
  constructor(private ngRedux: NgRedux<IAppState>, public http: HttpClient, private account: AccountService) {
    this.Init()
  }
  async Init() {
    await this.ngRedux.select(p => p.FormSettings.ProcessNum)
      .subscribe((processNum: string) => {
        this.processNum = processNum;
      }).unsubscribe();
    await this.ngRedux.select(p => p.FormSettings.RunNum)
      .subscribe((RunNum: string) => {
        this.RunNum = RunNum;
      }).unsubscribe();
  }

  public getProcessFiles(processNum: string, userInputs: JsonObjects.UserInputs) {
    const data = { "userInputs": JSON.stringify(userInputs) }
    return this.http.post(`${baseUrl}/api/PDF/GetProcessFiles?processNum=${processNum}`, data);
  }

  public getPdf(pdfFileId: string) {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this.http.get(`${baseUrl}/api/PDF/GetPDFFile?processNum=${this.processNum}&pdfFileId=${pdfFileId}`, { headers: headers, responseType: 'blob' })
  }

  public getPdfFields(jsonFieldsId: string): Observable<FormFieldData[][]> { //קבלת השדות של קובץ PDF
    return this.http.get(`${baseUrl}/api/PDF/GetPDFFields?processNum=${this.processNum}&jsonFieldsId=${jsonFieldsId}`).pipe(
      map((v) =>
        <FormFieldData[][]>v))
  }
  savePdf(userInputs: JsonObjects.UserInputs, account: string): Observable<FormComponent.MappedData<string>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
    headers = headers.set('Access-Control-Allow-Origin', '*');
    let body = new HttpParams();
    body = body.set('userInputs', JSON.stringify(userInputs));
    body = body.set('processNum', this.processNum);
    body = body.set('identityUser', this.RunNum);
    body = body.set('account', account);
    var msg = "";
    var statusReq = null;
    return this.http.post(`${baseUrl}/api/PDF/savePdf`, body, { headers: headers })
      .pipe(
        map((object: object) => {
          if (!!object == false) {
            msg = "הטופס מכיל נתונים שגויים";
            statusReq = FormComponent.eStatusRequestServer.RejectedRequest;
            this.account.addAccount();
          }
          else {
            msg = "שמחנו לקבל את פנייתך! ניצור קשר בהקדם  *3488|09-9579000";
            statusReq = FormComponent.eStatusRequestServer.SucceedRequest;
          }
          return {
            message: msg,
            statusRequest: statusReq
          }
        }),
        catchError(error => {
          console.log(error);
          return of(
            {
              message: "השליחה נכשלה,נסה שוב מאוחר יותר",
              statusRequest: FormComponent.eStatusRequestServer.FailRequest
            });
        }),
        startWith({
          message: "",
          statusRequest: FormComponent.eStatusRequestServer.InRequest
        }),
      );
  }
  postFile(files: Array<{ file: FileInput, control_id: string }>)
    : Observable<FormComponent.MappedData<string>> {
    let uploadURL = `${baseUrl}/api/PDF/UploadFile?processNum=${this.processNum}&runNum=${this.RunNum}`
    const formData: FormData = new FormData();
    let headers = new HttpHeaders();
    files.forEach(e => {
      if (e.file.files)
        e.file.files.forEach(f => {
          headers = headers.set('Accept', f.type);
          formData.append('fileKey', f, e.control_id);
        })
    });
    const req = new HttpRequest('POST', uploadURL, formData, {
      reportProgress: true,
      responseType: 'json',
      headers: headers
    });
    return this.http.request(req)
      .pipe(
        share(),
        map((event: HttpEvent<any>) => {
          if ((<HttpResponse<any>>event)?.headers != null) {// === HttpEventType.UploadProgress                        
            event = <HttpResponse<any>>event;
            if (event.ok) {
              console.log("ok")
              return {
                message: "העלאת קובץ הושלמה",
                statusRequest: FormComponent.eStatusRequestServer.SucceedRequest
              }
            }
            else {
              return {
                message: "",
                statusRequest: FormComponent.eStatusRequestServer.FailRequest
              }
            }
          }
          else {
            if ((event as HttpProgressEvent) != null) {
              let percent = Math.round(100 * (<HttpProgressEvent>event).loaded / (<HttpProgressEvent>event).total);
              console.log(`percent upload file is ${percent}%`);
            }
            return {
              message: "",
              statusRequest: FormComponent.eStatusRequestServer.InRequest
            }
          }
        }),
        catchError(error => {
          console.log(error);
          return of(
            {
              message: "השליחה נכשלה,נסה שוב מאוחר יותר",
              statusRequest: FormComponent.eStatusRequestServer.FailRequest
            });
        }),
        startWith({
          message: "",
          statusRequest: FormComponent.eStatusRequestServer.InRequest
        }),
      );
  }
  upload(file: File): Observable<HttpEvent<any>> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    const req = new HttpRequest('POST', `${baseUrl}/upload`, formData, {
      reportProgress: true,
      responseType: 'json'
    });

    return this.http.request(req);
  }

  public setClientSMS() {
    var subject = new BehaviorSubject<Array<UserInputs>>(null);
    const $UserDetailsEntered = this.ngRedux.select(o => o.UserDetailsEntered);
   const phone = $UserDetailsEntered.pipe(
      map(arr => arr.Children)
    ).subscribe(
      arr => {
        let requestType = arr.filter(x => x.Id == 'RequestTypeRequest_ZcpuHKsXN0_wdxdvD4bJdA_')[0].Children.find(x => x.Id == 'ae7beff4a5df4afbb161798179150e45').Value;
        subject.next(arr.filter(x => x.Id == 'AssociateInformation1_hQ3m82L52067YS6h9PUb7g_')[0].Children.find(x => x.Id == '4c3a0187a3ac4a70a7a4230f21e7bb9a').Value);
        if (requestType == "minor")
          subject.next(arr.filter(x => x.Id == 'AssociateInformation1_hQ3m82L52067YS6h9PUb7g_')[0].Children.find(x => x.Id == '5a0b4ac3fcd042fbbac857bc456a4aab').Value);
      });

    // const phone = $UserDetailsEntered.pipe(
    //   map(arr => arr.Children)
    // ).subscribe(
    //   arr => {
    //     subject.next(arr.filter(x => x.Id == 'AssociateInformation1_hQ3m82L52067YS6h9PUb7g_')[0].Children.find(x => x.Id == '4c3a0187a3ac4a70a7a4230f21e7bb9a').Value);
    //     if (subject.getValue().toString() == "")
    //       subject.next(arr.filter(x => x.Id == 'AssociateInformation1_hQ3m82L52067YS6h9PUb7g_')[0].Children.find(x => x.Id == '4c3a0187a3ac4a70a7a4230f21e7bb9a').Value);
    //   });


    //   var subject = new BehaviorSubject<Array<UserInputs>>(null);
    // const $UserDetailsEntered1 = this.ngRedux.select(o => o.UserForm);
    // $UserDetailsEntered1.subscribe(
    //   arr => {
    //     const phone= arr.value["4c3a0187a3ac4a70a7a4230f21e7bb9a"]
    //     console.log('phone: ', phone);
    //     subject.next(phone)
    //   });


    let body = new HttpParams();
    body = body.set('id', this.RunNum);
    body = body.set('phone', subject.getValue().toString());
    return this.http.post<any>(`${baseUrl}/api/PDF/setClientSMS`, body).toPromise();
  }
}


