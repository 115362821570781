import { Component, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from '../../shared/services/api.service';
import { NgRedux } from '@angular-redux/store';
import { FormControl, FormGroup } from '@angular/forms';
import { CounterActions } from '../../../store/actions'//'src/store/actions';
import { IAppState } from '../../../../src/store/reducer';
import { CustomValidation } from '../../../../src/app/shared/custom-validation';
import { Observable, Subscription, Subject } from 'rxjs';
import { AbstractControl } from '../../shared/services/abstractControl.service'
import * as JsonObjects from '../../../../src/app/shared/classes';
import { ConditionalLogic } from '../../../../src/app/shared/services/conditionalLogic.service';
import { LevelSectionService } from '../../shared/services/levelSection.service';
import { FormComponent } from '../../../../src/app/shared/structureObject';
import { Serializable } from '../../../../src/app/shared/classes/Serializable';
import { Section } from '../../../../src/app/shared/classes';
import { ResponsiveService } from '../../../../src/app/shared/services/resonsive.service';

export interface IHash {
  [nameComponent: string]: JsonObjects.Text[];
}
@Component({
  selector: 'app-level',
  templateUrl: './level.component.html',
  styleUrls: ['./level.component.scss']
})

export class LevelComponent implements OnInit, OnDestroy {
  currentLevel: JsonObjects.RefJsonFile;//השלב הנוכחי
  currentLevelSettings: JsonObjects.Level;//הגדות שלב נוכחי
  sectionData$: Observable<any>;//המקטע מהשרת
  userForm: FormGroup = new FormGroup({});//טופס משתמש
  nevigate: string;// הבא לא כולל אינדקס שלב urlה
  indexLevel: string;//אינקס שלב
  formSettings: FormComponent.GeneralSetting;//הגדרות טופס משתנה בהמשך התהליך
  isCompletedFormGroup: boolean = false;//האם הושלם יצירת טופס
  SectionArray: {//מערך מקטעים לשלב לא מכילים מקטעים כפולים
    [key: string]: JsonObjects.FormSection;
  } = {};
  expansionPanelSection: {//מערך המקטעים שהם בתצוגת לוח הרחבה
    [key: string]: {
      isOpen: boolean;//הגדרה האם המקטע פתוח
      isDisable: boolean;//הגדרה האם המקטע מושבת
    }
  } = {};
  isSectionsConditionTrue: { [key: string]: boolean } = {};//מערך מרכזי! ששומר לכל פקד האם הוא מוצג או לא
  sectionsCompletedArray: string[]; //שמירת המקטעים שהושלמו
  //שלב משני
  secondaryProcessLevels: JsonObjects.RefJsonFile = { Copy: "", Num: "", CompName: "", Template: "", Hierarchy: "", Children: [] };//איחסון כל השלבים שבתהליך המשני מהשרת
  private subscription: Subscription = new Subscription();
  get eDevice() {
    return FormComponent.eDevice;
  }
  get device() {
    return this.responsiveService.device;
  }
  constructor(private responsiveService: ResponsiveService, private cd: ChangeDetectorRef, public apiService: ApiService, private ngRedux: NgRedux<IAppState>,
    private actions: CounterActions, public router: Router, public route: ActivatedRoute, private abstractControl: AbstractControl,
    private customValidation: CustomValidation, private conditionalLogic: ConditionalLogic, private levelSectionService: LevelSectionService) {
    customValidation.getErrorMessages();
  }

  ngOnInit(): void {
    this.subscription.add(this.ngRedux.select(o => o.FormSettings).subscribe((formSettings: FormComponent.GeneralSetting) => {
      //חסר למקטעים שבתוך מקטעים לכן אני חושבת שכל מקטע יבדוק האם השגיאה אצלו
      // let ServerErrorMessage: FormComponent.ErrorServer = this.ngRedux.getState().FormSettings.ServerErrorMessage
      // if (statusUser == FormComponent.eStatusUser.InLastLevel && ServerErrorMessage != undefined)
      //   this.arrSection.find(p => p.currentSection.SectionNum == ServerErrorMessage.NumSection).setServerErrorMessageControl(ServerErrorMessage)
      this.formSettings = formSettings;
    }));
    this.subscription.add(this.route.paramMap.subscribe(paramMap => this.indexLevel = paramMap.get('id')));//עידכון אינקס שלב
    //this.subscription.add(
    this.ngRedux.select(o => o.UserForm).subscribe((userForm: FormGroup) => this.userForm = userForm)


    //);//שליפת טופס משתמש
    this.subscription.add(this.ngRedux.select(o => o.router).subscribe((router: string) => {
      this.nevigate = router.toString().split('/')[1] + '/' + router.toString().split('/')[2];//עידכון הכתובת הבאה לא כולל אינדקס שלב
      let statusUserSecondaryProcess = this.formSettings.SecondaryProcess.StatusUser;
      if (statusUserSecondaryProcess == FormComponent.eStatusUser.StartProcess && this.secondaryProcessLevels.Children.length == 0)//התפריט מטפל בניווט תהליך ראשי וכאן השלב מטפל בניווט של תהליך משני
      {
        var fillSecondaryProcessLevels;
        let numSecondaryProcess = (this.currentLevelSettings as JsonObjects.RefLevel).RefLevelProcess;
        this.ngRedux.dispatch(this.actions.actions.changeNumSecondaryProcess(numSecondaryProcess));
        this.apiService.GetProcessMenu(numSecondaryProcess).toPromise().then((JsonSecondaryProcess: string) =>  //אם מתחילים תהליך משני ואין את השלבים אז יש לייבא מהשרת
        {
          var secondaryProcessLevelAndControl: JsonObjects.RefJsonFile = JsonSecondaryProcess != null ? Serializable.fromJSON('RefJsonFile', JsonSecondaryProcess) : { Num: '', CompName: "", Children: [] as JsonObjects.RefJsonFile[], Template: "", Hierarchy: "" }
          this.apiService.getLevel(this.currentLevel.Num, this.currentLevel.Template).toPromise().then(//שליפת הגדרות של השלב הנוכחי
            (responseLevel) => {
              var refLevel: JsonObjects.RefLevel = Serializable.fromJSON('RefLevel', responseLevel);//המרה מגיסון לאוביקט
              let indexRefLevel = secondaryProcessLevelAndControl.Children.findIndex(level => level.Num == refLevel.RefLevelNum);//מציאת השלב שממנו מתחיל התהליך
              indexRefLevel = indexRefLevel >= 0 ? indexRefLevel : 0;//בדיקה: אם לא מצא את השלב אז שיתחיל מההתחלה
              fillSecondaryProcessLevels = secondaryProcessLevelAndControl.Children.slice(indexRefLevel).map((Level: JsonObjects.RefJsonFile) => {//השלבים של תהליך המשני      
                return new Promise((resolve) => { this.secondaryProcessLevels.Children.push(Level); resolve(undefined); })//מעבר על כל שלב
              });
              Promise.all(fillSecondaryProcessLevels).then(() => {
                this.setCurrentSecondaryLevel();
              });
            })
        })
      }
      else {
        let indexLevelSecondaryProcess: number = +this.router.url.split('/')[5];
        let countLevelSecondaryProcess: number = this.secondaryProcessLevels.Children.length;
        if (countLevelSecondaryProcess > indexLevelSecondaryProcess)//כל עוד הלקוח לא סיים את כל השלבים
          this.setCurrentSecondaryLevel();//יש לשלוף את השלב הבא          
        if (countLevelSecondaryProcess == indexLevelSecondaryProcess + 1)//כל עוד הלקוח לא סיים את כל השלבים  
        {
          this.ngRedux.dispatch(this.actions.actions.changeStatusUserSecondaryProcess(FormComponent.eStatusUser.InLastLevel));
        }
      }
    }));

    this.cd.detectChanges();//מעדכן תצוגה

    //שליפת השלב הנוכחי כל פעם כשמישתנה השלב הנוכחי
    this.subscription.add(this.ngRedux.select('Currentlevel').subscribe((updateCurrentLevel: JsonObjects.RefJsonFile) => {
      var isChangeLevel: boolean = false;//מיצג האם ישתנה השלב
      this.isCompletedFormGroup = false;//לא הושלם יצירת טופס      
      this.ngRedux.dispatch(this.actions.actions.changeIsCompletedSections(false));//עדכון ברידקס שלא הושלמו בניית המקטעים      
      this.levelSectionService.saveDataSection(); //שמירת המקטעים הקודמים
      //עדכון המקטעים המושבתים
      var disabledSectionsId: string[] = Object.keys(this.isSectionsConditionTrue).filter(sectionId =>
        !this.isSectionsConditionTrue[sectionId]
      )
      if (disabledSectionsId.length > 0)
        this.ngRedux.dispatch(this.actions.actions.updateDraftComponents(disabledSectionsId));

      //עדכון המקטעים המאופשרים
      var enabledSectionsId: string[] = Object.keys(this.isSectionsConditionTrue).filter(sectionId =>
        this.isSectionsConditionTrue[sectionId]
      )
      if (enabledSectionsId.length > 0)
        this.ngRedux.dispatch(this.actions.actions.updateDisDraftComponents(enabledSectionsId));

      if (!this.currentLevel || this.currentLevel.Num != updateCurrentLevel.Num || updateCurrentLevel.Template == "refLevel")//אם מגיעים לשלב אחר
      {
        isChangeLevel = true;
        this.abstractControl.initFormControls();//איתחול פקדי טופס
        this.currentLevel = updateCurrentLevel;
        this.conditionalLogic.clearRecheckConditionalLogic();//איפוס האזנות לבדיקת תצוגה מותנית רק כשמגיעים לשלב אחר
        this.sectionsCompletedArray = [];//איפוס נמקטעים שהושלמו
        this.levelSectionService.clearSavingSectionSubject();//איפוס האזנה של המקטעים לשמירה  
      }
      else {//אם מוסיפים SECTION ולא משנים שלב
        //group by

        updateCurrentLevel.Children.forEach((section, index) => {
          let i = this.currentLevel.Children.findIndex(p => { return p.Num + p.Copy == section.Num + section.Copy })
          if (i == -1) {
            this.currentLevel.Children.splice(index, 0, section)//  הוספה במידה ולא נמצא במעודכן
          }
          else
            //בגלל השינוי בכמות המקטעים המוכפלים Hierarchy עדכון השדה
            if (section.Hierarchy != this.currentLevel.Children[i].Hierarchy)
              this.currentLevel.Children[i].Hierarchy = section.Hierarchy;
        });
        //למחוק מהמערך כאשר כפתור מוחק סקשן
        this.currentLevel.Children.forEach((section, index) => {
          let i = updateCurrentLevel.Children.findIndex(p => { return p.Num + p.Copy == section.Num + section.Copy })
          if (i == -1)//
          {
            let idExtension = section.Copy != null ? `_COPY_${section.Copy}` : '';
            let updateformControls: { [key: string]: FormControl | FormGroup } = {};// פקדי הטופס מעודכן
            Object.keys(this.abstractControl.formControls).forEach(controlId => {
              if (this.SectionArray[section.Num].Controls.findIndex(c => c.ControlId + idExtension == controlId) == -1)
                updateformControls[controlId] = this.abstractControl.formControls[controlId];
            })//מחיקת הפקדים מהשירות
            this.abstractControl.formControls = updateformControls;//עדכון פקדי הטופס
            this.currentLevel.Children.splice(index, 1)//מחיקה במידה ולא נמצא במקורי
          }
        });
      }

      this.isSectionsConditionTrue = {};
      if (this.formSettings.SecondaryProcess.StatusUser == FormComponent.eStatusUser.InLastLevel)//כאשר המשתמש עובר לשלב האחרון של פרופיל סיכון שבו הוא מחשבן את התוצאות
      {
        this.endSecondaryProcess().finally(() => {
          this.createFromControls(isChangeLevel, updateCurrentLevel);
        })
      }
      else {
        this.createFromControls(isChangeLevel, updateCurrentLevel);
      }
    }))

    //שליפת הגדרות שלב נוכחי כל פעם כשמישתנה
    this.subscription.add(this.ngRedux.select('CurrentLevelSettings').subscribe((CurrentLevelSettings: JsonObjects.Level) => {
      this.currentLevelSettings = CurrentLevelSettings;
    }))
  }
  createFromControls(isChangeLevel: boolean, updateCurrentLevel: JsonObjects.RefJsonFile) {
    var completedFormGroup = this.setSectionsDataIntoFormGroup(isChangeLevel);//הכנסת המקטעים לטופס
    if (completedFormGroup) {

      Promise.all(completedFormGroup).then(() => {//יופעל רק אחרי שהושלם יצירת הטופס
        //כשהושלמו ההבטחות של יצירת טופס
        this.levelSectionService.setSectionArray(this.SectionArray);//עדכון בשירות את מערך המקטעים המעודכן
        let formControls = this.abstractControl.getFormControls();//קבלת פקדי טופס
        this.ngRedux.dispatch(this.actions.actions.updateUserForm(formControls))//הכנס את הפקדים לטופס משתמש ברידקס
        this.isCompletedFormGroup = true;//הושלם יצירת טופס

        let ServerErrorMessage: FormComponent.ErrorServer = this.ngRedux.getState().FormSettings.ServerErrorMessage
        if (ServerErrorMessage != undefined)//קבלת שגיאות מהשרת
        {
          let formInput: JsonObjects.Input = this.SectionArray[ServerErrorMessage.NumSection].Controls.find(control => control.ControlId == ServerErrorMessage.ControlId)
          this.userForm.controls[ServerErrorMessage.ControlId] = this.customValidation.addValidatorServer(this.userForm.controls[ServerErrorMessage.ControlId] as FormControl, ServerErrorMessage.ErrorMessage, formInput);
          this.ngRedux.dispatch(this.actions.actions.changeServerErrorMessage(undefined));//שמירת שלב נוכחי ברידקס      
        }

        //הוספת תצוגה מותנת למקטעים המוצבעים לפי המקע המצביע
        if (updateCurrentLevel.Children)
          updateCurrentLevel.Children.forEach(currentSection => {
            this.SetRefSectionConditionalLogicGroup(this.SectionArray[currentSection.Num], currentSection)
          });

        //הוספת האזנות למקטעים אם יופיעו או לא
        if (updateCurrentLevel.Children)
          updateCurrentLevel.Children.forEach(currentSection => {
            this.checkSectionConditionIsAppear(this.SectionArray[currentSection.Num], currentSection, isChangeLevel)
          });
      })

    }
  }
  getInformationSections() {
    return this.currentLevel.Children.filter(s => s.Template == 'information');
  }

  setSectionCompleted(sectionNum: string) {//הגדרתה שהמקטע הושלם
    if (this.sectionsCompletedArray.findIndex(s => s == sectionNum) == -1) {
      this.sectionsCompletedArray.push(sectionNum);//הכנסת המקטע שהושלם
      //בדיקה אם כל המקטעים הושלמו
      var sectionNotComplete = this.currentLevel.Children.findIndex(section =>
        this.isSectionsConditionTrue[section.Num] && //המקטע מופיע בתצוגה
        section.Template != "text" && //המקטע אינו מסוג טקסט
        this.sectionsCompletedArray.findIndex(s => s == section.Num) == -1 &&//המקטע לא הושלם
        !this.SectionArray[section.Num].Pointed//והמקטע הוא אינו מוצבע
      )

      if (sectionNotComplete == -1)//אם לא נמצא מקטע שלא הושלם
      {
        this.ngRedux.dispatch(this.actions.actions.changeIsCompletedSections(true));//עדכון ברידקס שכל המקטעים הושלמו
        this.checkExpansionPanelSection();//בדיקת המקטעים לוח הרחבה אם יוצגו כדיסייבל או לא
      }

    }
  }

  saveDataFrom() {//שמירת נתוני משתמש
    this.levelSectionService.saveDataSection(); //שמירת המקטעים הקודמים
  }

  //מקבל מקטע מחזירExtension
  getIdExtension(sectionWithProperty: JsonObjects.RefJsonFile) {
    let idExtension = sectionWithProperty.Copy != null ? `_COPY_${sectionWithProperty.Copy}` : '';//הוספת מזהה עותק
    return idExtension;
  }
  setCurrentSecondaryLevel() {//הכנסת השלבים הבאים בתהליך המשני
    //לא כל שלב מגיע מהשרת רק לאלו ששיש להם תצוגה מותנית
    this.apiService.getLevel(this.secondaryProcessLevels.Children[this.router.url.split('/')[5]].Num, this.secondaryProcessLevels.Children[this.router.url.split('/')[5]].Template).toPromise().then(//שליפת השלב הבא
      responseLevel => {
        var level = Serializable.fromJSON('Level', responseLevel);//המרה מגיסון לאוביקט
        //אם ירצו לשמור הגדות שלב משני - יש לשמור מקומית ולא ברידקס
        //בדיקת התצוגה מותנית
        if (level.LevelNum && !this.conditionalLogic.checkCondition(false, level.LevelNum, level.ConditionalLogicGroup)) { //אם התנאי לא התקיים            
          var lastIndex: number = this.secondaryProcessLevels.Children.findIndex(level => level.Num == this.currentLevel.Num);//השלב האחרון שהמשתמש היה
          var updateIndex = lastIndex < +this.router.url.split('/')[5] ? +this.router.url.split('/')[5] + 1 : +this.router.url.split('/')[5] - 1;//האם צריך להתקדם או ללכת אחורה
          let nextNevigate = this.nevigate + '/' + this.indexLevel + '/secondaryProcess/' + updateIndex;
          this.router.navigateByUrl(nextNevigate);
        }
        else {//במידה וlevel ריק נכנס לכאן
          var changeCurrentLevel = JSON.parse(JSON.stringify(this.currentLevel));
          changeCurrentLevel.Children = this.secondaryProcessLevels.Children[this.router.url.split('/')[5]].Children;//מתחילים לפי מי שהצביעו עליו
          this.ngRedux.dispatch(this.actions.actions.changeCurrentLevel(changeCurrentLevel));//שמירת שלב נוכחי ברידקס          
        }
      }
    )
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  setSectionsDataIntoFormGroup(isChangeLevel: boolean): Promise<any>[] {//הכנסת המקטעים לטופס
    return this.currentLevel.Children ? //אם קיימים מקטעים בשלב הנוכחי
      this.currentLevel.Children.map((section, index) => {//שליפת המקטעים של השלב הנוכחי
        let idExtension = section.Copy != null ? `_COPY_${section.Copy}` : '';//הוספת מזהה עותק

        return new Promise((resolve) => {
          if (isChangeLevel)//אם מגיעים לשלב אחר
          {
            this.SectionArray = {};//איפוס מערך המקטעים
            //שליפת המקטע מהשרת
            this.sectionData$ = this.apiService.getSection(section.Num, section.Template);
            this.sectionData$.toPromise().then((json: any) => {
              var spliteType = json.$type.split('.');
              var typeObject = spliteType[spliteType.length - 1].split(',')[0]
              var sectionData: JsonObjects.FormSection = Serializable.fromJSON(typeObject, json);

              var sectionControls = sectionData != null ? sectionData.Controls : [];

              //יש להכניס מקטעים לשלב הנוכחי אם המקטע קיים ויש לו פקדים והוא אינו מושבת
              if (!sectionData.Disabled) {
                if ((sectionControls != undefined && sectionControls.length > 0) || section.Template == "text") {//למקטע יש פקדים או שהוא מקטע טקסט                          
                  this.SectionArray[section.Num] = sectionData;  //הכנסה למערך
                  if (this.SectionArray[section.Num] instanceof JsonObjects.FormSection) {
                    let c: JsonObjects.Control = this.SectionArray[section.Num].Controls.find(c => c.ControlType == 'headerSection')
                    if (c != undefined) {
                      //הוספה כותרת מקטע אייקון                    
                      let icon: string = (c as JsonObjects.HeaderSection).Icon;
                      section.CompName = icon == undefined ? c.Label : icon + c.Label;
                    }
                    else {
                      section.CompName = ""; //יש את הכותרת שמופיע במניושלא רוצים לעצב
                    }
                  }
                }
              }
              if (this.SectionArray[section.Num]) {
                if (section.Template != "text") {
                  //אם המקטע מוכפל יש לשרשר לכותרת המקטע את מספרו הסידור
                  var IsSectionCopy = this.SectionArray[section.Num].Controls.filter(c => c.ControlType == 'button')
                    .findIndex(button => (button as JsonObjects.Button).Event == 'deleteSection');
                  section.Hierarchy = IsSectionCopy >= 0 ?
                    (this.currentLevel.Children.filter(s => s.Num == section.Num).findIndex(s => s.Copy == section.Copy) + 1).toString() : ''
                }
                this.afterCurrentLevelChange(section, idExtension, resolve);
              }
              else {//לא הוכנס למערך המקטעים
                //סימן שהמקטע ריק או מושבת או לא קיים
                //לכן יש למחוק אותו מהשלב הנוכחי
                this.currentLevel.Children.splice(index, 1);//מחיקת השלב מהתפריט
                this.ngRedux.dispatch(this.actions.actions.changeCurrentLevel(this.currentLevel));//שמירת שלב נוכחי ברידקס
                resolve(undefined);
              }
            });
          }
          else {
            this.afterCurrentLevelChange(section, idExtension, resolve);
          }

        })
      }) : [new Promise((resolve) => {
        resolve(null)
      })];
  }

  afterCurrentLevelChange(section: JsonObjects.RefJsonFile, idExtension: string, resolve) {//עדכונים אחרי שהשלב עודכן
    section.Template != "text" ?//רק למקטעים שמכילים פקדים יוצרים טופס
      this.abstractControl.FillControlsFormGroup(section, this.SectionArray[section.Num].Controls, resolve)//יצירת טופס
      : resolve(null)
    if ((this.SectionArray[section.Num] as Section).IsExpansionPanel && !this.expansionPanelSection[section.Num])//בדיקה אם המקטע הוא מוצג כלוח הרחבה
    {
      //מילוי המשתנה שמציג את המקטעים שהם לוח הרחבה                
      if (section.CompName == "")//פאנל ללא כותרת תמיד פתוח
        this.expansionPanelSection[section.Num] = { isOpen: true, isDisable: false };
      else
        this.expansionPanelSection[section.Num] = { isOpen: false, isDisable: true };
    }

  }

  SetRefSectionConditionalLogicGroup(sectionWithControls: JsonObjects.FormSection, sectionWithProperty: JsonObjects.RefJsonFile) {
    if (this.SectionArray[sectionWithControls.SectionNum].Controls)
      this.SectionArray[sectionWithControls.SectionNum].Controls.forEach(control => {
        if (control.ControlType == 'refSection') {
          if (control.ConditionalLogicGroup.Activated == true || this.SectionArray[sectionWithControls.SectionNum].ConditionalLogicGroup.Activated == true) {
            var ConditionalLogicGroup = control.ConditionalLogicGroup.Activated ? control.ConditionalLogicGroup : this.SectionArray[sectionWithControls.SectionNum].ConditionalLogicGroup as JsonObjects.ConditionalLogicGroup;
            var controlPointed = control as JsonObjects.RefSection;
            if (this.SectionArray[controlPointed.SectionNum].ConditionalLogicGroup.Activated == false) {
              this.SectionArray[controlPointed.SectionNum].ConditionalLogicGroup = ConditionalLogicGroup;
            }
          }
        }
      })
  }

  checkSectionConditionIsAppear(sectionWithControls: JsonObjects.FormSection, sectionWithProperty: JsonObjects.RefJsonFile, isChangeLevel: boolean) {
    let idExtension = sectionWithProperty.Copy != null ? `_COPY_${sectionWithProperty.Copy}` : '';//הוספת מזהה עותק
    if (sectionWithControls.ConditionalLogicGroup.Activated == true) {//אם יש למקטע תצוגה מותנית

      this.subscription.add(this.conditionalLogic.recheckConditionalLogic[sectionWithControls.SectionNum + idExtension] = new Subject<any>());
      this.subscription.add(this.conditionalLogic.recheckConditionalLogic[sectionWithControls.SectionNum + idExtension].subscribe(() => {
        let isCondition: boolean = this.conditionalLogic.checkCondition(false, sectionWithControls.SectionNum + idExtension, sectionWithControls.ConditionalLogicGroup, false);//בדיקת התצוגה מותנית                
        if (this.isSectionsConditionTrue[sectionWithControls.SectionNum + idExtension] != isCondition) {
          if (isCondition) {
            this.conditionalLogic.enableControlFormSection(this.SectionArray[sectionWithControls.SectionNum].Controls, idExtension);
          }
          else//הורדת האזנות לולידשן
            this.conditionalLogic.disableControlFormSection(this.SectionArray[sectionWithControls.SectionNum].Controls, idExtension);
        }
        this.isSectionsConditionTrue[sectionWithControls.SectionNum + idExtension] = isCondition;
      }));

      let isConditionOnInit: boolean = this.conditionalLogic.checkCondition(false, sectionWithControls.SectionNum + idExtension, sectionWithControls.ConditionalLogicGroup, true);//recheckConditionalLogic  בדיקת התצוגה מותנית בעת איתחול יצירת האזנות הכנסה של האזנות לתוך      
      this.isSectionsConditionTrue[sectionWithControls.SectionNum + idExtension] = isConditionOnInit;
      if (!isConditionOnInit)//בודק עבור סקשן בעת איתחול האם  עונה לתצוגה מותנית במידה ולא מבצע דיסאבל לפקדים
        this.conditionalLogic.disableControlFormSection(this.SectionArray[sectionWithControls.SectionNum].Controls, idExtension);
    }
    else
      this.isSectionsConditionTrue[sectionWithControls.SectionNum + idExtension] = true//הצג מקטע -אין תצוגה מותנית

    if (isChangeLevel && this.SectionArray[sectionWithControls.SectionNum].Pointed)//מקטע מוצבע המקטעים שלו דיסביילים עד שהפקד שמצביע למקטע הופך אותו לאינייבל
      this.conditionalLogic.disableControlFormSection(this.SectionArray[sectionWithControls.SectionNum].Controls, idExtension);
  }
  checkExpansionPanelSection() {//בדיקת המקטעים לוח הרחבה אם יוצגו כדיסייבל או לא
    var currentSections = this.currentLevel.Children.filter(section => this.SectionArray[section.Num].IsExpansionPanel && this.isSectionsConditionTrue[section.Num] && section.CompName != "");//the sections displayed in the panels (not pointed ones which are inside)

    if (currentSections)
      currentSections.map((currentSection, index) => {//מעבר על המקטעים של השלב הנוכחי
        //האזנה לטופס משתמש
        //כשפקדי המקטע הקודמים תקינים אז המקטע הנוכחי לא מושבת
        var previousControlId: { [key: string]: string } = {};//מערך לשמירת מזהים של פקדים קודמים עם הסטטוס שלהם
        var lastIndex = currentSections.findIndex(s => s.Num == currentSection.Num);

        for (let index = 0; index < lastIndex; index++) {
          var prevCurrentSection = currentSections[index];

          if (prevCurrentSection.Template != "text") {
            var prevCurrentControls = this.SectionArray[prevCurrentSection.Num].Controls.filter(control => this.userForm.controls[control.ControlId] &&
              control.ControlType != 'textControl' && control.ControlType != "header" && control.ControlType != "headerSection");
            if (prevCurrentControls)
              prevCurrentControls.forEach((control, i) => {//עובר על הפקדים שקיימים בטופס
                previousControlId[control.ControlId] = this.userForm.controls[control.ControlId].status;
                //האזנה לפקד כשבודקים את הסטטוס של הפקד כאשר רוצים לאפשר פתיחה של הקטע בודקים את כל הפקדים שהוא תלוי בהם
                this.subscription.add(this.userForm.controls[control.ControlId].statusChanges.subscribe((status) => {
                  previousControlId[control.ControlId] = status;//עדכון סטטוס פקד
                  // VALID: This control has passed all validation checks.
                  // INVALID: This control has failed at least one validation check.
                  // PENDING: This control is in the midst of conducting a validation check.
                  // DISABLED:This control is exempt from validation checks. These status values are mutually exclusive, so a control cannot be both valid AND invalid or invalid AND disabled.
                  if (status != 'INVALID')// לא חוקי אם הפקד לא
                  {
                    setTimeout(() => {
                      //אז תבדוק ששאר הפקדים שלפני המקטע הנוכחי גם כן חוקיים
                      if (this.expansionPanelSection[currentSection.Num].isDisable && Object.keys(previousControlId).findIndex(key => previousControlId[key] == 'INVALID') == -1)//אם לא נמצאו פקדים שהם לא חוקיים
                      {
                        this.expansionPanelSection[currentSection.Num].isDisable = false;//יש לעדכן את המקטע כלא מושבת
                        //num of last section which is displayed in the screen
                        if (this.SectionArray[prevCurrentSection.Num].Controls.filter(control => previousControlId[control.ControlId] != "DISABLED" && control.ControlType != 'textControl' && control.ControlType != "header" && control.ControlType != "headerSection" && control.ControlType != "input")[this.SectionArray[prevCurrentSection.Num].Controls.filter(control => previousControlId[control.ControlId] != "DISABLED" && control.ControlType != 'textControl' && control.ControlType != "header" && control.ControlType != "headerSection" && control.ControlType != "input").length - 1].ControlId == control.ControlId)//אם הפקד נמצא במקטע שמעליו וגם הוא הפקד האחרון
                          this.expansionPanelSection[currentSection.Num].isOpen = true;//יש לפתוח את המקטע הבא                    
                      }

                    }, 500);
                  }
                  else//אם הפקד לא חוקי
                    if (!this.expansionPanelSection[currentSection.Num].isDisable)
                      this.expansionPanelSection[currentSection.Num].isDisable = true;
                }))

              })

          }
        }
        // אחרי מעבר על כל הפקדים של המקטעים הקודמים
        //יש לבדוק האם המקטע הנוכחי מאופשר
        if (this.expansionPanelSection[currentSection.Num].isDisable && Object.keys(previousControlId).findIndex(key => previousControlId[key] == 'INVALID') == -1)//אם לא נמצאו פקדים שהם לא חוקיים          
        {
          this.expansionPanelSection[currentSection.Num].isDisable = false;//יש לעדכן את המקטע כלא מושבת
          this.cd.detectChanges();
        }

        if (!this.expansionPanelSection[currentSection.Num].isOpen && (Object.keys(previousControlId).length == 0 || Object.keys(previousControlId).findIndex(key => previousControlId[key] == 'INVALID') == -1) && currentSection.Num!='ManagementFee_ipfOA2ZKoUq8ZuhFGpMDtg_')//אם אין למקטע פקדים שהוא מסתמך עליו
        {
          this.expansionPanelSection[currentSection.Num].isOpen = true;//יש לפתוח את המקטע
          this.cd.detectChanges();
        }
      });

  }
  expansionPanelOpen(sectionNum: string) {//כשלוח הרחבה של מקטע נפתח    
    //יש לסגור את המקטעים
    Object.keys(this.expansionPanelSection).filter(sNum => sNum != sectionNum && this.expansionPanelSection[sNum].isOpen).forEach((sNum: string) => {
      this.expansionPanelSection[sNum].isOpen = false;
    });

    if (this.expansionPanelSection[sectionNum] && !this.expansionPanelSection[sectionNum].isOpen)//אם המקטע קיים ברשימת המקטעים המוצגים כלוח הרחבה
      this.expansionPanelSection[sectionNum].isOpen = true;//ונעדכן שהמקטע הנוכחי פתוח    
    this.cd.detectChanges();//מעדכן תצוגה
  }

  getSectionByControl(controlId: string) {
    let sectionId = Object.keys(this.SectionArray).find(sectionId => {
      if (this.SectionArray[sectionId].Controls)
        return this.SectionArray[sectionId].Controls.find(control => control.ControlId == controlId) != undefined
    });
    return sectionId;
  }
  getSectionBySectionNum(sectionIdPoint: string) {
    let sectionId = Object.keys(this.SectionArray).find(sectionId => {
      return this.SectionArray[sectionId].Controls.some(control => {
          if (control.ControlType === 'refSection') {
              const controlPointed = control as JsonObjects.RefSection;
              if (controlPointed.SectionNum === sectionIdPoint) {
                  return true;
              }
          }
      });}
    );
    return sectionId;
  }

  checkSectionIsPoint(sectionId:string){
    if (this.SectionArray[sectionId].Pointed) {
      this.checkSectionIsPoint(this.getSectionBySectionNum(sectionId))
    }
    else
      this.markALLErrorsInSection(sectionId)
  }
  // getInvalidControlBySection(controlsFromSection: []){
  //   return controlsFromSection.find((control: any) =>{
  //     return Object.keys(this.userForm.controls).find((key) => {
  //       return control.ControlId == key && this.userForm.controls[key].invalid == true
  //     });
  //   })
  // }

  getInvalidControlBySection(controlsFromSection: any[]) {
    for (const key of Object.keys(this.userForm.controls)) {
      if (controlsFromSection.find((control) => control.ControlId === key && this.userForm.controls[key].invalid)) {
        return this.userForm.controls[key];
      }
    }
    return null;
  }
  public invalidUserForm() {
    var firstcontrolIdInvalId = null;
    this.currentLevel.Children.some((section) => {
      for (const key of Object.keys(this.userForm.controls)) {
        if (section.Num === this.getSectionByControl(key?.includes('COPY') ? key.replace(/_COPY_.*/, "") : key) && this.userForm.controls[key].invalid) {
          firstcontrolIdInvalId = key;
          return true; // break the loop
        }
      }
    });


    // var firstcontrolIdInvalid = firstcontrolIdInvalId;
    var firstSectionIdInvalId = this.getSectionByControl(firstcontrolIdInvalId?.includes('COPY') ? firstcontrolIdInvalId.replace(/_COPY_.*/, "") : firstcontrolIdInvalId)
    if (!this.expansionPanelSection[firstSectionIdInvalId] && !firstSectionIdInvalId?.includes('signature')) {
      var sec = this.FindNumRefSection(firstSectionIdInvalId);
      firstSectionIdInvalId = this.getSectionByControl(sec);
    }


    if (firstcontrolIdInvalId) {

      //שליחה הID כדי לטפל במקטע
      //טיפול גם בכל השגויים במקטע
      this.checkSectionIsPoint(firstSectionIdInvalId);
      // this.markALLErrorsInSection(firstSectionIdInvalId);

      this.userForm.controls[firstcontrolIdInvalId].markAsDirty();
      if (this.userForm.controls[firstcontrolIdInvalId]) {

      }
      var element = $("#" + firstcontrolIdInvalId)
      if (element.is('canvas') == true) {
        (<HTMLElement>element[0]).tabIndex = 0;
        element.focus();
      }
      else {
        if ($("#" + firstcontrolIdInvalId).find('mat-radio-button').length > 0) {
          (<HTMLElement>$("#" + firstcontrolIdInvalId + " mat-radio-button")[0]).focus();//לפקד radio button
          (<HTMLElement>$("#" + firstcontrolIdInvalId + " mat-radio-button")[0]).scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        else {
          if (document.getElementById(firstcontrolIdInvalId)) {
            if (document.getElementById(firstcontrolIdInvalId).classList.contains('radio-toggle')) {
              document.getElementById(firstcontrolIdInvalId).scrollIntoView({ behavior: 'smooth', block: 'center' });
              document.getElementById(firstcontrolIdInvalId).focus;
            }
            // document.getElementById(firstcontrolIdInvalId).scrollIntoView({ behavior: 'smooth', block: 'center' });
            document.getElementById(firstcontrolIdInvalId).scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center'
            })

            $("#" + firstcontrolIdInvalId).focus();//לפקד רגיל
          }
          else {
            if (document.getElementById(firstcontrolIdInvalId + "buttonlabel")) {
              document.getElementById(firstcontrolIdInvalId + "buttonlabel").scrollIntoView({ behavior: 'smooth', block: 'center' });
              document.getElementById(firstcontrolIdInvalId + "buttonlabel").focus;
            }
            else {
              if ($("#" + firstcontrolIdInvalId).find('mat-checkbox').length > 0) {
                $("#" + firstcontrolIdInvalId).find('mat-checkbox').focus()
              }
              else {
                if (document.getElementById(firstcontrolIdInvalId))
                  document.getElementById(firstcontrolIdInvalId).scrollIntoView({ behavior: 'smooth', block: 'center' });
                $("#" + firstcontrolIdInvalId).focus();//לפקד רגיל
              }
            }
          }
        }
      }
    }
  }




  public FindNumRefSection(sectionNum: String) {
    var x
    Object.keys(this.SectionArray).map(section => {
      if (this.SectionArray[section].Controls) {
        return this.SectionArray[section].Controls.find(control => {
          if (control.ControlType == "refSection") {
            if ((control as JsonObjects.RefSection).SectionNum == sectionNum) {
              x = control.ControlId;
            }
          }

        })
      }
    });
    return x
  }


  public markALLErrorsInSection(sectionId: string) {//מעבר על הSECTION ,פתיחתו והדגשת כל השגויים במקטע
    this.expansionPanelOpen(sectionId);//פתיחת לוח הרחבה אם המקטע מוצג כלוח הרחבה
    $("#" + sectionId).focus;
    this.SectionArray[sectionId].Controls.map((MyElement) => {//מעבר על כל הפקדים בSECTION וסימון הבעיה
      this.userForm.controls[MyElement.ControlId]?.markAsDirty();
    });
  }


  endSecondaryProcess(): Promise<any> {//הלקוח סיים את התהליך המשני
    //העברת הלקוח לשלב האחרון
    var changeCurrentLevel = JSON.parse(JSON.stringify(this.currentLevel));
    changeCurrentLevel.Children = null;
    var userDetailsSecondaryProcess = { Id: "userInputs", Value: "userInputs", Children: [] } as JsonObjects.UserInputs;
    //שליפת הערכים שהלקוח הזין בתהליך המשני מהרידקס
    this.secondaryProcessLevels.Children.forEach(level => {
      level.Children.map(section => {
        var idExtension = section.Copy != null ? `_COPY_${section.Copy}` : '';//הוספת מזהה עותק
        //הכנסת ערכי הלקוח למשתנה זמני
        var userInput: JsonObjects.UserInputs = this.ngRedux.getState().UserDetailsEntered.Children.find(userInputSection => userInputSection.Id == section.Num + idExtension)
        if (userInput) {
          userDetailsSecondaryProcess.Children.push(userInput);
        }
      })
    })

    //שליכת ערכי הלקוח לשרת כדי לקבל תוצאות התהליך
    return this.apiService.getProcessResults(userDetailsSecondaryProcess, (this.currentLevelSettings as JsonObjects.RefLevel).RefLevelProcess).toPromise()

      .then(//שליפת הגדרות של השלב הנוכחי
        (json: any) => {
          if (json) {
            var spliteType = json.$type.split('.');
            var typeObject = spliteType[spliteType.length - 1].split(',')[0]
            var processResult = Serializable.fromJSON(typeObject, json);//JsonObjects.FormSection
            //יש לעדכן ולעשות המרה לסיווג רק אחרי שיודעים שהוא בפרופיל סיכון
            if (processResult instanceof JsonObjects.Classification) {
              var classification: JsonObjects.Classification = processResult as JsonObjects.Classification
              //לשמור את התוצאה ברידקס
              var section: JsonObjects.UserInputs;
              section = { Id: (this.currentLevelSettings as JsonObjects.RefLevel).RefLevelProcess, Value: this.currentLevel.CompName, Type: "rickProfileProcess", Children: [] } as JsonObjects.UserInputs;
              classification.DistributionRoutes.forEach(route => {//הוספת המסלולים לשמירה ברידקס
                section.Children.push({ Id: route.RouteId, Value: route.RoutePercent, Type: "DistributionRoute" })
              })
              section.IsDraft = true;
              this.ngRedux.dispatch(this.actions.actions.insertInputsComponent(section));
            }
          }
        })
  }
  isAsideApear() {
    return this.currentLevel && this.currentLevel.Children.findIndex(s => s.Template == 'information') != -1
  }
  isFinishLoad() {
    return this.currentLevel.Children.length >= (Object.keys(this.SectionArray).length);
  }
}