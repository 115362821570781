import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { ErrorHandlerService } from './error-handler.service';
import * as JsonObjects from '../classes'
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../store/reducer';
import { catchError, map, startWith } from 'rxjs/operators';
import { FormComponent } from '../structureObject';
import { UserInputs } from '../../shared/classes';

const baseUrl = //"https://localhost:44358";//local
'https://join.infinity.co.il/dataapi';//prod
//'https://dev-join.infinity.co.il/dataapi';//dev

@Injectable({ providedIn: 'root' })
export class ApiService //implements HttpInterceptor 
{
  typesValidation$: BehaviorSubject<JsonObjects.TypeInput[]> = new BehaviorSubject([] as JsonObjects.TypeInput[]);
  public errorMessage: string = '';
  processNum: string;
  RunNum: string;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  constructor(private ngRedux: NgRedux<IAppState>, public errorHandler: ErrorHandlerService, private http: HttpClient) {
  }

  async Init() {
    this.getTypesValidation();
    await this.ngRedux.select(p => p.FormSettings.ProcessNum)
      .subscribe((processNum: string) => {
        this.processNum = processNum;
      }).unsubscribe();
    await this.ngRedux.select(p => p.FormSettings.RunNum)
      .subscribe((RunNum: string) => {
        this.RunNum = RunNum;
      }).unsubscribe();
  }

  GetProcessMenu(secondaryProcessNum = ''): Observable<any> {
    this.Init();
    return this.http.get(`${baseUrl}/api/values/GetProcessMenu?processNum=` + (secondaryProcessNum ? secondaryProcessNum : this.processNum ? this.processNum : 'customer_registration_4456'));
  }

  getProcess(processNum, template): Observable<any> {
    this.Init();
    return this.http.get(`${baseUrl}/api/values/GetProcess?processNum=` + processNum + '&processTemplate=' + template);
  }

  getLevel(levelNum, template): Observable<any> {
    return this.http.get(`${baseUrl}/api/values/GetLevel?processNum= ` + this.processNum + "&levelNum=" + levelNum + "&levelTemplate=" + template);
  }

  getSection(sectionNum, template): Observable<object> {
    return this.http.get(`${baseUrl}/api/values/GetSection?processNum= ` + this.processNum + "&sectionNum=" + sectionNum + "&sectionTemplate=" + template);
  }

  getRefSectionByNum(sectoinName): Observable<any> {
    return this.http.get(`${baseUrl}/api/values/GetRefSectionByNum?processNum=` + this.processNum + "&sectionNum=" + sectoinName);
  }

  isValidInput(userInputs: JsonObjects.UserInputs): Observable<object> {
    var Data = { "userInputs": JSON.stringify(userInputs), "sectionTemplate": "form" }
    return this.http.post(`${baseUrl}/api/values/IsValidInput?processNum= ` + this.processNum, Data)
  }

  getProcessResults(userInputs: JsonObjects.UserInputs, processInputsNum = ''): Observable<object> {//קבלת תוצאות התהליך
    var Data = { "userInputs": JSON.stringify(userInputs), "sectionTemplate": "form" }
    return this.http.post(`${baseUrl}/api/values/GetProcessResults?processInputsNum=` + processInputsNum, Data)
  }
  getErrorMessages() {
    return this.http.get(`${baseUrl}/api/values/GetErrorMessages`);
  }
  getTypesValidation() {
    this.http.get(`${baseUrl}/api/values/GetTypesValidetion`).toPromise().then((response) => {
      // const arrTypeInput:JsonObjects.TypeInput[]=Serializable.fromJSON('TypeInput', response);
      let previousValidaion = this.typesValidation$.value;
      const arrTypeInput: JsonObjects.TypeInput[] = response as JsonObjects.TypeInput[];
      previousValidaion = arrTypeInput;
      this.typesValidation$.next(previousValidaion);
    });
  }


  sendEmailcontrolDatailesEmail(controls: JsonObjects.ControlDatailesEmail[]): Observable<FormComponent.MappedData<string>> {
    var Data = { "controlsDatailesEmail": JSON.stringify(controls) }

    return this.http.post(`${baseUrl}/api/values/sendEmailcontrolDatailesEmail?processNum=${this.processNum}&identityUser=${this.RunNum}`, Data)
      .pipe(
       
        map((object: object) => {
          return {
            message: "שמחנו לקבל את פנייתך! ניצור קשר בהקדם  *3488|09-9579000",
            statusRequest: FormComponent.eStatusRequestServer.SucceedRequest
          }
        }),
        catchError(error => {
          console.log(error);
          return of(
            {
              message: "השליחה נכשלה, נסה שוב מאוחר יותר",
              statusRequest: FormComponent.eStatusRequestServer.FailRequest
            });
        }),
        startWith({
          message: "",
          statusRequest: FormComponent.eStatusRequestServer.InRequest
        }),
      );
  }
  sendEmailwithDatailsEmail(userInputs: JsonObjects.UserInputs, notificationControl: JsonObjects.Notification)
  : Observable<FormComponent.MappedData<string>>  
  {
    var Data = { "userInputs": JSON.stringify(userInputs), "notificationControl": JSON.stringify(notificationControl) }
    return this.http.post(`${baseUrl}/api/values/sendEmailwithDatailsEmail?processNum=${this.processNum}&identityUser=${this.RunNum}`, Data)
    .pipe(
      map((object:any) => {
        return {
          message: "",
          statusRequest: FormComponent.eStatusRequestServer.SucceedRequest
        }
      }),
      catchError(error => {
        console.log(error);
        return of(
          {
            message: "",
            statusRequest: FormComponent.eStatusRequestServer.FailRequest
          }

        );
      }),         
      startWith({
        message: "",
        statusRequest: FormComponent.eStatusRequestServer.InRequest
      }),  
      )    
  }

  // CopyFilesInOutpuFolder(userInputs: JsonObjects.UserInputs, notificationControl: JsonObjects.Notification)
  // : Observable<FormComponent.MappedData<string>>  
  // {
  //   var Data = { "userInputs": JSON.stringify(userInputs), "notificationControl": JSON.stringify(notificationControl) }
  //   return this.http.post(`${baseUrl}/api/values/CopyFilesInOutpuFolder?processNum=${this.processNum}&identityUser=${this.RunNum}`, Data)
  //   .pipe(
  //     map((object:any) => {
  //       return {
  //         message: "",
  //         statusRequest: FormComponent.eStatusRequestServer.SucceedRequest
  //       }
  //     }),
  //     catchError(error => {
  //       console.log(error);
  //       return of(
  //         {
  //           message: "",
  //           statusRequest: FormComponent.eStatusRequestServer.FailRequest
  //         }

  //       );
  //     }),         
  //     startWith({
  //       message: "",
  //       statusRequest: FormComponent.eStatusRequestServer.InRequest
  //     }),  
  //     )    
  // }

  sendEmailGeneral(typeEmail: string, datailsEmail: JsonObjects.UserInputs): Observable<object> {
    var Data = { "datailsEmail": JSON.stringify(datailsEmail), subject: "contact" }
    return this.http.post(`${baseUrl}/api/values/sendEmail?processNum=${this.processNum}&identityUser=${this.RunNum}&&typeEmail=${typeEmail}`, Data).pipe(
      catchError(error => {
        console.log(error);
        alert("שגיאה" + error.message)
        return throwError(error);
      }))
  }

  public sendOTP() {
    var subject = new BehaviorSubject<Array<UserInputs>>(null);
    const $UserDetailsEntered = this.ngRedux.select(o => o.UserForm);
    $UserDetailsEntered.subscribe(
      arr => {
        let requestType = arr.value["ae7beff4a5df4afbb161798179150e45"];
        let phoneType = "4c3a0187a3ac4a70a7a4230f21e7bb9a";
        if (requestType == "minor")
        {
            phoneType = "5a0b4ac3fcd042fbbac857bc456a4aab";
        }
        const phone= arr.value[phoneType]
        console.log('phone: ', phone);
        subject.next(phone)
      });
    // const phone = $UserDetailsEntered.pipe(
    //   map(arr => arr.value)
    // ).subscribe(
    //   arr => {
    //     subject.next(arr.filter(x => x.Id == 'AssociateInformation1_hQ3m82L52067YS6h9PUb7g_')[0].Children.find(x => x.Id == 'f47c88a403ea499ba5712c7da48203a4').Value);
    //     if (subject.getValue().toString() == "")
    //       subject.next(arr.filter(x => x.Id == 'AssociateInformation1_hQ3m82L52067YS6h9PUb7g_')[0].Children.find(x => x.Id == '4c3a0187a3ac4a70a7a4230f21e7bb9a').Value);
    //   });
    console.log('subject.getValue(): ', subject.getValue());

    let body = new HttpParams();
    body = body.set('id', this.RunNum);
    body = body.set('phone', subject.getValue().toString());
    return this.http.post<any>(`${baseUrl}/api/values/setClientSMSOtp`, body).toPromise();
  }

  public checkOtpPassward(password:string){
    let body = new HttpParams();
    body = body.set('id', this.RunNum);
    body = body.set('password', password);
    return this.http.post<any>(`${baseUrl}/api/values/checkOtpPassword`, body).toPromise();
  }

  public SendPotentialCustomerDetails() {
    var subject = new BehaviorSubject<any>(null);
    const $UserDetailsEntered = this.ngRedux.select(o => o.UserForm);
    $UserDetailsEntered.subscribe(
      arr => {
        const customerDetails = {
          phone: arr.value["4c3a0187a3ac4a70a7a4230f21e7bb9a"],
          firstName: arr.value["6554d40aa2bc448db2d5effd54fd58d7"],
          lastName: arr.value["88021372d84540869ee15fd46ba9b8b6"],
          email: arr.value["82357bcc06c74cf3847f10090d087d6d"]
        };
        subject.next(customerDetails);
      });

    let body = new HttpParams();
    const customerDetails = subject.getValue();
    body = body.set('id', this.RunNum);
    body = body.set('phone', customerDetails.phone);
    body = body.set('firstName', customerDetails.firstName);
    body = body.set('lastName', customerDetails.lastName);
    body = body.set('email', customerDetails.email);
    return this.http.post<any>(`${baseUrl}/api/values/setPotentialCustomer`, body).toPromise();
  }
}
