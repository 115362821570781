import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NgRedux } from '@angular-redux/store';
import { IAppState } from '../../../../store/reducer';
import { CounterActions } from '../../../../store/actions';
import { Subscription } from 'rxjs';
import * as JsonObjects from '../../classes'

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss']
})
export class ButtonComponent implements OnInit, OnDestroy {

    @Input() input: JsonObjects.Button;//הפקד הנוכחי
    @Input() currentComponent: JsonObjects.RefJsonFile;//המקטע הנוכחי עם המאפיינים שלו
    @Input() userForm: FormGroup;//טופס משתמש

    idExtension: string = '';//מזהה עותק
    currentLevel: JsonObjects.RefJsonFile;//השלב הנוכחי
    multiplicationSection: JsonObjects.RefJsonFile;//המקטע להכפלה
    nextMultiplySectionId: number=0;//ערך מזהה המקטע המוכפל הבא
    countSection: number;//כמות עותקים למקטע
    classList: string[] = [];//קלאסים של הפקד הנוכחי

    private subscription: Subscription = new Subscription();

    constructor(private ngRedux: NgRedux<IAppState>, private actions: CounterActions) {
    }

    ngOnInit() {
        this.classList = this.input.Class ? this.input.Class.split(' ') : [];//שליפת הקלאסים של הפקד
        this.idExtension = this.currentComponent.Copy != null ? `_COPY_${this.currentComponent.Copy}` : '';//הכנסת מזהה עותק
        this.subscription.add(this.ngRedux.select('Currentlevel').subscribe((currentLevel: JsonObjects.RefJsonFile) => {//עדכון השלב הנוכחי
            this.currentLevel = JSON.parse(JSON.stringify(currentLevel));
            this.countSection = this.getCountSection();//קבלת כמות העותקים קל המקטע
        }))

        if (this.input.Event == "multiplySection") {
            this.multiplicationSection=this.ngRedux.getState().Currentlevel.Children.filter(section=>section.Num==this.input.SectionNum)[0];//שליפה מהרדקס את השלב שיהיה מוכפל בעת ילחצו על כפתור זה
            this.nextMultiplySectionId = this.userForm.controls[this.input.ControlId + this.idExtension].value;//שליפת ערך המזהה הבא       
            this.nextMultiplySectionId=this.nextMultiplySectionId.toString()==""?0:this.nextMultiplySectionId
        }
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    isClassExists(isClass: string = '') {
        return this.classList && this.classList.findIndex(c => c == isClass) >= 0;
    }

    eventButton() {
        if (this.input.Event == "multiplySection")
            this.multiplySection();//הכפלת מקטע
        else if (this.input.Event == "deleteSection")
            this.deleteSection();//מחיקת מקטע

    }

    getCountSection(): number {
        return this.currentLevel.Children ?
            this.currentLevel.Children.filter(section => section.Num == this.input.SectionNum).length ://שליפת כמות ההכפלה של המקטע הנוכחי
            0
    }

    IsLastToBeDeleted() {//בדיקה האם נשאר מקטע אחד אחרון למחיקה- כדי למנוע מחיקת המקטע הזה
        if (this.input.Event == "deleteSection" && this.countSection <= 1)//אם הכפתור למחיקה וגם נשאר מקטע אחד
            return true;
        return false;
    }

    multiplySection() {
        var updateLevel = this.currentLevel;
        if (!this.input.QuantityLimit || parseInt(this.input.QuantityLimit) > this.countSection) {//בדיקה שכמות ההכפלה לא עברה את המקסימליות להכפלה
            var iLastMultiplySection = this.currentLevel.Children.map(section => section.Num).lastIndexOf(this.input.SectionNum);//קבלת מזהה מקטע שהוכפל האחרון ביותר
            //אנו מונעים את המחיקה של האחרון
            //במידה ויצטרכו למחוק גם את האחרון אז אחרי כן כשמשתמש עושה הוסף יש צורך לראות איפה המקטע אמור להיות- וזה על ידי קבלת כל השלב מהשרת ולא רק את הקומפוננטה להכפלה
            //כי כך אפשר לדעת איפה הוא אמור להיות
            var copyMultiplySection = JSON.parse(JSON.stringify(this.multiplicationSection))//העתקה מקטע

            updateLevel.Children.splice(iLastMultiplySection + 1, 0, copyMultiplySection);//לאחר המטע הנוכחי הקודם -הכפלת המקטע בשלב הנוכחי
            updateLevel.Children[iLastMultiplySection + 1].Copy = this.nextMultiplySectionId.toString();//הכנסה לשלב המוכפל מזהה עותק
            updateLevel.Children[iLastMultiplySection + 1].Hierarchy= this.currentLevel.Children.filter(s => s.Num == this.input.SectionNum).length.toString();//הגדרת המספר הסידורי של המקטע המוכפל

            this.ngRedux.dispatch(this.actions.actions.addCopySectionInputsComponent(this.input.SectionNum,this.nextMultiplySectionId));           
            ++this.nextMultiplySectionId;//הגדרת המזהה הבא
            this.userForm.controls[this.input.ControlId + this.idExtension].setValue(this.nextMultiplySectionId)//עדכון ערך פקד כפתור (המזהה הבא)  
            this.currentLevel = updateLevel;
            this.ngRedux.dispatch(this.actions.actions.changeCurrentLevel(updateLevel));//שמירת שלב נוכחי ברידקס
        }
    }

    deleteSection() {
        var updateLevel = this.currentLevel;
        var currentCopySection = updateLevel.Children.find(section => section.Num == this.currentComponent.Num && section.Copy == this.currentComponent.Copy);//קבלת המקטע/ הקומפוננטה הנוכחית למחיקה
        if (this.countSection > 1) {//מניעת מחיקת האחרון
            var indexCurrentComponent: number = updateLevel.Children.indexOf(currentCopySection);
            if (indexCurrentComponent !== -1)              
            {
                updateLevel.Children.splice(indexCurrentComponent, 1);//מחיקת השלב מהתפריט
                for (let index = indexCurrentComponent; index < updateLevel.Children.length && updateLevel.Children[index].Num==currentCopySection.Num; index++) {
                   updateLevel.Children[index].Hierarchy=(+currentCopySection.Hierarchy+(index-indexCurrentComponent)).toString();
                }
                this.currentLevel = updateLevel;
                this.ngRedux.dispatch(this.actions.actions.changeCurrentLevel(this.currentLevel));//שמירת שלב נוכחי ברידקס
                this.ngRedux.dispatch(this.actions.actions.updateDraftComponents([this.input.SectionNum + this.idExtension]));
            }
        }

    }
}